import Auth from "./auth";
import initRoot from "./react";

document.addEventListener("DOMContentLoaded", function () {
  initRoot().catch(console.warn);
  Auth();
  const fieldsContainer = document.querySelector("section.fields");
  if (fieldsContainer) {
    import("./settings/addingFields")
      .then(({ default: addingFields }) => addingFields())
      .catch(console.warn);
  }
});
