type TagsToLoad = {
  styles: string[];
};

type WindowWithTags = typeof window & { tagsToLoad: TagsToLoad };

class ReactRoot {
  private shadowRoot: ShadowRoot;
  private styleTags: HTMLElement[] = [];
  public appInner?: HTMLElement;
  public modalContainer?: HTMLElement;
  public root?: HTMLElement;
  public stylesRoot?: HTMLElement;

  constructor(app: HTMLElement) {
    app.innerHTML = "";
    this.shadowRoot = app.attachShadow({ mode: "closed" });
    this.initStyles();
    this.initRoot();
    setTimeout(() => this.removeMetaDatas(), 0);
  }

  private initStyles() {
    const tagsToLoad = (window as WindowWithTags).tagsToLoad;

    const styles: string[] = [];

    if (tagsToLoad.styles && Array.isArray(tagsToLoad.styles)) {
      styles.push(...tagsToLoad.styles);
    }

    this.styleTags = styles.map((src) => {
      const linkElement = document.createElement("link");
      linkElement.setAttribute("rel", "stylesheet");
      linkElement.setAttribute("href", src);
      return linkElement;
    });
  }

  private initRoot() {
    this.root = document.createElement("div");
    this.root.setAttribute("id", "root");
    this.shadowRoot?.appendChild(this.root);

    this.stylesRoot = document.createElement("div");
    this.stylesRoot.setAttribute("id", "styles_root");
    this.root.appendChild(this.stylesRoot);

    this.appInner = document.createElement("div");
    this.appInner.setAttribute("id", "app_root");
    this.root.appendChild(this.appInner);

    this.modalContainer = document.createElement("div");
    this.modalContainer.setAttribute("id", "modal_root");
    this.modalContainer.classList.add("relative", "z-[10000]");
    this.root.appendChild(this.modalContainer);

    this.styleTags.forEach((tag) => {
      this.stylesRoot?.appendChild(tag);
    });
  }

  private removeMetaDatas() {
    document.querySelectorAll("[data-meta-additional]").forEach((meta) => {
      meta.remove();
    });
  }
}

class InitApp extends HTMLElement {}
customElements.define("init-app", InitApp);

const initRoot = async () => {
  const app = (document.querySelector("init-app") ||
    document.getElementById("app")) as HTMLElement;

  if (app && !app.dataset.page) {
    const reactRoot = new ReactRoot(app);
    const { default: initReact } = await import("./pages");

    if (reactRoot.appInner && reactRoot.stylesRoot && reactRoot.modalContainer)
      initReact(
        reactRoot.appInner,
        reactRoot.stylesRoot,
        reactRoot.modalContainer,
      );
  }
};

export default initRoot;
