function Auth() {
  if (window.location.pathname.startsWith("/login")) {
    import("./login")
      .then((imported) => imported.default())
      .catch(console.warn);
  }

  if (window.location.pathname.startsWith("/register")) {
    import("./register")
      .then((imported) => imported.default())
      .catch(console.warn);
  }

  if (window.location.pathname.startsWith("/restore")) {
    import("./restore")
      .then((imported) => imported.default())
      .catch(console.warn);
  }
}

export default Auth;
